import React from "react"
import { graphql } from "gatsby"
import { Box, Typography } from "@mui/material"
import LocalizedLink from "../components/LocalizedLink"
import { LoginContainer } from "../components"

const MyAccountPageTemplate = ({ data }) => {
  const { title, subtitle_parts, login, forgot_password } =
    data.markdownRemark.frontmatter

  return (
    <Box component="section" mx={2} py={8}>
      <Box mx="auto" textAlign="center" mb={4}>
        <Typography component="h1" variant="h4" color="#000065" mb={4}>
          {title}
          <Typography component="span" color="secondary" variant="h4">
            .
          </Typography>
        </Typography>
        <Typography>{subtitle_parts.top}</Typography>
        <Typography>
          {subtitle_parts.bottom.title}{" "}
          <LocalizedLink to={subtitle_parts.bottom.link_link}>
            <Typography component="span" color="primary" fontWeight="bold">
              {subtitle_parts.bottom.link_title}
            </Typography>
          </LocalizedLink>
        </Typography>
      </Box>
      <LoginContainer data={login} />
      <Box mx="auto" textAlign="center">
        <LocalizedLink to="/" style={{ textDecoration: "none" }}>
          <Typography
            component="span"
            color="primary"
            fontWeight="bold"
            sx={{
              position: "relative",
              "&::after": {
                position: "absolute",
                content: "''",
                bottom: "-5px",
                left: 0,
                right: 0,
                height: "1px",
                backgroundColor: "#000065",
              },
            }}
          >
            {forgot_password}
          </Typography>
        </LocalizedLink>
      </Box>
    </Box>
  )
}

export default MyAccountPageTemplate

export const query = graphql`
  fragment LoginPage on MarkdownRemarkFrontmatter {
    login {
      button
      email
      password
    }
    title
    forgot_password
    subtitle_parts {
      top
      bottom {
        link_title
        link_link
        title
      }
    }
  }
`
